import React, { useEffect } from "react";
import DashboardLayout from "../../components/SharedLayouts/DashboardLayout";
import UserCard from "../../components/cards/UserCard";

import Loader from "../../components/SharedLayouts/Loader";
import { useDispatch, useSelector } from "react-redux";
import AdminUserIcon from "../../components/Icons/AdminUserIcon";
import { getAllUser } from "../../store/actions/userManagement/management";
import { Link } from "react-router-dom";
import AccountSummaryTable from "../../components/dashboard/accountSummary/AccountSummaryTable";
import useCurrency from "../../hooks/useCurrency";

function AccountSummary() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state?.user?.allUsersPending);
  const fetchSuccess = useSelector(
    (state) => state?.userManagement?.fetchSuccess
  );
  const { allUsers, allUsersInfo } = useSelector((state) => state?.user);

  const user = useSelector((state) => state.onboarding.user);

  const [_currency, getAmount] = useCurrency();
  useEffect(() => {
    dispatch(getAllUser());
  }, []);

  const walletBalances = allUsers?.filter((user) => user?.wallet !== null);
  const UserDetails = [
    {
      id: 1,
      icon: <AdminUserIcon />,
      title: "Total Accounts",
      cardValue: allUsersInfo?.total,
    },
    {
      id: 2,
      icon: <AdminUserIcon />,
      title: "Total Wallet Balance",
      cardValue:
        _currency === "₦"
          ? `₦${getAmount(allUsersInfo?.total_wallet_balance, 2)}`
          : `$${getAmount(allUsersInfo?.total_wallet_balance_usd, 2)}`,
    },
  ];

  return (
    <DashboardLayout>
      <div className="px-4 md:px-8 ">
        <div className="flex items-center mb-8 md:flex-row md:items-center w-full">
          <Link to={"/dashboard"}>
            <img src="/Assets/bread.png" alt="" className="w-8 h-8 mr-2" />
          </Link>
          <img src="/Assets/chev.png" alt="" className="w-8 h-8 mr-2" />
          <span className="font-semibold text-[#475467]text-sm sm:text-base bg-[#F9FAFB] p-1 rounded-md  w-[max-content]">
            Account Summary
          </span>
        </div>

        <h1 className="mb-6 font-[500] text-xl md:text-2xl lg:text-3xl">
          Account Summary
        </h1>
        {loading ? (
          <Loader />
        ) : (
          user?.roles &&
          !user?.roles?.some((role) => role.name === "Admin_Tier_2") && (
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {UserDetails?.map((detail, index) => (
                <div key={index} className="mb-4">
                  <UserCard
                    currency="NG"
                    currencyCode="NGN"
                    width={"100%"}
                    height={188}
                    icon={detail.icon}
                    title={detail.title}
                    cardValue={detail.cardValue}
                  />
                </div>
              ))}
            </div>
          )
        )}

        <div className="mt-6">
          {user?.roles &&
            !user?.roles?.some((role) => role.name === "Admin_Tier_2") && (
              <AccountSummaryTable />
            )}
        </div>
      </div>
    </DashboardLayout>
  );
}

export default AccountSummary;
