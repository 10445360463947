import React, { useEffect, useState } from "react";
import Loader from "../../SharedLayouts/Loader";
import Dots from "../../Icons/Dots";
import { Box } from "@mui/system";
import GridTable from "../../table/gridTable";
import { useDispatch, useSelector } from "react-redux";
import useCurrency from "../../../hooks/useCurrency";
import { getUserDetail } from "../../../store/actions/userManagement/management";
import { useParams } from "react-router-dom";
import AdminUserIcon from "../../Icons/AdminUserIcon";
import UserCard from "../../cards/UserCard";
import { fetchTransactions } from "../../../store/actions/userManagement/getTransactionHistory";
import { getCurrencySymbol } from "../../../Helper/getCurrencySymbol";

function AccountSummaryDetailTable() {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [filteredRows, setFilteredRows] = useState([]);
  const [, setShowPopper] = useState(false);
  const [_currency, getAmount] = useCurrency();
  const currency = useSelector((state) => state.dashboard.currency) || "NGR";
  const loading = useSelector((state) => state?.user?.loading);
  const userTransactions =
    useSelector((state) => state?.user?.userTransactions) || [];
  const { id } = useParams();

  const transactions = userTransactions.filter(
    (transaction, index) => transaction.currency === currency
  );

  useEffect(() => {
    dispatch(fetchTransactions(id));
    dispatch(getUserDetail({ id }));
  }, [dispatch, id]);

  const userDetail = useSelector((state) => state?.user?.user?.data) || null;

  const columns = [
    {
      field: "transactionID",
      headerName: "Transactio ID",
      width: 150,
    },

    { field: "narration", headerName: "Narration", width: 200 },
    {
      field: "debit",
      headerName: "Debit",
      width: 150,
      renderCell: (params) => params.row?.debit,
    },
    {
      field: "credit",
      headerName: "Credit",
      width: 150,
      renderCell: (params) => params.row?.credit,
    },
    {
      field: "walletBalance",
      headerName: "Wallet Balance",
      width: 200,
      renderCell: (params) => params.row?.walletBalance,
    },
    {
      field: "date",
      headerName: "Date",
      width: 200,
    },

    {
      width: 40,
      renderCell: (params) => (
        <>
          <div
            style={{
              cursor: "pointer",
              position: "relative",
            }}
            onClick={(e) => {
              e.target.setAttribute("data-is-icon", "true");
              // handleContextMenu(e);
            }}
          >
            <Dots />
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (anchorEl) {
      setShowPopper(true);
    }

    return () => {
      setAnchorEl(null);
    };
  }, [anchorEl]);

  const CardDetails = [
    {
      id: 1,
      icon: <AdminUserIcon />,
      title: "Total Transactions",
      cardValue: userTransactions?.length,
    },
    {
      id: 2,
      icon: <AdminUserIcon />,
      title: "Total Wallet Balance ",
      cardValue:
        _currency === "₦"
          ? `₦${getAmount(userDetail?.wallet?.walletBalance || 0, 2)}`
          : `$${getAmount(userDetail?.wallet?.dollarBalance || 0, 2)}`,
    },
  ];

  const handleSearch = (value) => {
    setSearchTerm(value);
  };
  useEffect(() => {
    const rows = transactions?.map((req, index) => ({
      id: index,
      transactionID: req?.transaction_id,
      narration: req?.transaction_type,
      debit:
        req?.transaction_side === "Debit"
          ? `${getCurrencySymbol(req?.currency)}${getAmount(req?.amount)}`
          : "",
      credit:
        req?.transaction_side === "Credit"
          ? `${getCurrencySymbol(req?.currency)}${getAmount(req?.amount)}`
          : "",
      walletBalance: `${getCurrencySymbol(req?.currency)} ${getAmount(
        req?.wallet_balance
      )}`,
      date: new Date(req?.transaction_date).toLocaleDateString("en-US", {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
      }),
    }));

    setFilteredRows(
      searchTerm === ""
        ? rows
        : rows.filter(
            (row) =>
              row.transactionID
                .toLowerCase()
                .includes(searchTerm.toLowerCase()) ||
              row.narration.toLowerCase().includes(searchTerm.toLowerCase())
          )
    );
  }, [transactions, searchTerm]);
  return (
    <div className="mt-10 mb-8">
      {/* <div className="flex justify-between mb-8">
        <div
          className="search-wrapper hidden lg:flex items-center "
          style={{ width: "400px", height: "44px" }}
        >
          <SearchIcon />
          <input
            onChange={(e) => handleSearch(e.target.value)}
            placeholder="Search for transactions"
          />
        </div>
      </div> */}

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {CardDetails?.map((detail, index) => (
          <div key={index} className="mb-4">
            <UserCard
              currency="NG"
              currencyCode="NGN"
              width={"100%"}
              height={188}
              icon={detail?.icon}
              title={detail?.title}
              cardValue={detail?.cardValue}
            />
          </div>
        ))}
      </div>

      <div className="bg-white p-6 rounded-lg shadow-md">
        <div className="flex justify-between mb-4 items-center">
          <h1 className="font-[600]">{userDetail?.name}</h1>

          {/* <ExportExcel rows={filteredRows} columns={columns} /> */}
        </div>
        {!loading ? (
          filteredRows?.length === 0 ? (
            <div className="  p-5 text-center">No Transactions</div>
          ) : (
            <Box sx={{ overflowX: "auto", width: "100%", cursor: "pointer" }}>
              <GridTable
                type={"account summary detail"}
                searchPlaceholder={"Search for transactions"}
                rows={filteredRows}
                columns={columns}
                getRowId={(row) => row?.id}
                handleRowClick={() => {}}
              />
            </Box>
          )
        ) : (
          <Loader />
        )}
      </div>
    </div>
  );
}

export default AccountSummaryDetailTable;
